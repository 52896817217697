import { create } from 'zustand';

import { ExcoverTopicsSearchDto, ExcoverTopicsSearchEntity, TopicEntity, TopicWithTypeEntity } from '../lib/excover';

type TopicsStore = {
  topics: Record<string, TopicWithTypeEntity>;
  visibleTopics: TopicWithTypeEntity[];
  topicsRequest: ExcoverTopicsSearchDto;
  topicsResponse: ExcoverTopicsSearchEntity;
  queryString: string;
  setQueryString: (queryString: string) => void;
  setTopicsRequest: (topicsRequest: ExcoverTopicsSearchDto) => void;
  setTopicsResponse: (topicsResponse: ExcoverTopicsSearchEntity) => void;
  cacheAndMergeTopics: ({
    topics,
    fields,
    subfields,
  }: {
    topics?: TopicEntity[];
    fields?: TopicEntity[];
    subfields?: TopicEntity[];
  }) => Promise<TopicWithTypeEntity[]>;
  getTopicById: (id: string) => TopicWithTypeEntity;
  getTopicsByIds: (ids: string[]) => TopicWithTypeEntity[];
  setVisibleTopics: (topics: TopicWithTypeEntity[]) => void;
};

export const useTopicsStore = create<TopicsStore>((set, get) => ({
  topics: {},
  visibleTopics: [],
  topicsRequest: null,
  topicsResponse: null,
  queryString: '',

  setTopicsRequest: (topicsRequest) => set({ topicsRequest }),
  setTopicsResponse: (topicsResponse) => set({ topicsResponse }),

  setQueryString: (queryString) => set({ queryString }),

  cacheAndMergeTopics: async ({ topics, fields, subfields }) => {
    const { topics: currentTopics } = get();

    const topicsWithTypes: TopicWithTypeEntity[] = topics?.map((topic) => ({ ...topic, type: 'topic' }));
    const fieldsWithTypes: TopicWithTypeEntity[] = fields?.map((field) => ({ ...field, type: 'field' }));
    const subfieldsWithTypes: TopicWithTypeEntity[] = subfields?.map((subfield) => ({ ...subfield, type: 'subfield' }));

    const newTopics = [...topicsWithTypes, ...fieldsWithTypes, ...subfieldsWithTypes];

    // Merge new topics with existing topics
    const mergedTopics = newTopics.reduce(
      (acc, topic) => {
        const existingTopic = currentTopics[topic.id];
        if (existingTopic) {
          acc[topic.id] = { ...existingTopic, ...topic };
        } else {
          acc[topic.id] = topic;
        }
        return acc;
      },
      { ...currentTopics },
    );

    set({ topics: mergedTopics });

    return newTopics;
  },

  getTopicById: (id) => {
    return get().topics[id];
  },

  getTopicsByIds: (ids) => {
    const topics = get().topics;
    return ids.map((id) => topics[id]);
  },

  setVisibleTopics: (topics) => {
    set({ visibleTopics: topics });
  },
}));
