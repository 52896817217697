import { ArrowDropDown, ChevronLeft, ChevronRight, Close, InfoOutlined, StarBorder } from '@mui/icons-material';
import {
  Alert,
  Box,
  IconButton,
  Link,
  List,
  MenuItem,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { ChangeEvent } from 'react';
import { useCookies } from 'react-cookie';

import { useUser } from '../../../hooks/features/useUser';
import { AnalyticsUtils } from '../../../lib/analytics';
import { config } from '../../../lib/config';
import { ExcoverQueryDto, PaginationEntity } from '../../../lib/excover';
import { WebsiteNavigation } from '../../../lib/navigation';
import { SearchEntity } from '../../../lib/types';
import { SearchUtils } from '../../../utils/SearchUtils';
import { ExternalLink } from '../../common/ExternalLink';
import { CustomButton } from '../../new/CustomButton';
import { PublicationCard } from '../../publication/PublicationCard/PublicationCard';

interface OnPaginationChangeResult {
  pagination: PaginationEntity;
  previousPage: number;
  newPage: number;
  totalPages: number;
  newLimit?: number;
}

export interface ISearchResultsProps {
  loading?: boolean;
  request?: ExcoverQueryDto;
  response?: SearchEntity;
  pagination?: PaginationEntity;
  onPaginationChange?: (pagination: OnPaginationChangeResult) => void;
  NoResultsComponent: React.ReactNode;
  AppliedFiltersComponent: React.ReactNode;
  PaginationSignUpComponent: React.ReactNode;
  NumberOfResultsComponent: React.ReactNode;
  SignUpToGetEnglishArticlesComponent: React.ReactNode;
  children: React.ReactNode;
}

export const SearchResults: React.FC<ISearchResultsProps> = ({
  loading,
  response,
  pagination,
  onPaginationChange,
  NoResultsComponent,
  AppliedFiltersComponent,
  PaginationSignUpComponent,
  NumberOfResultsComponent,
  SignUpToGetEnglishArticlesComponent,
  children,
}) => {
  const { user, institution, isPlusUser } = useUser();

  // Cookies
  const CROSS_LANGUAGE_ALERT_COOKIE = 'cross-language-alert-closed';
  const [cookies, setCookie] = useCookies([CROSS_LANGUAGE_ALERT_COOKIE]);

  if (loading || !pagination || !response) {
    return (
      <Box sx={{ mt: 4, mx: 4 }}>
        {AppliedFiltersComponent}

        <Box sx={{ mt: 2 }}>
          <Box sx={{ mt: 4 }}>
            <Stack direction="row" sx={{ mb: 4, alignItems: 'center', justifyContent: 'space-between' }}>
              <Skeleton width={200} />

              <Skeleton width={100} />
            </Stack>
          </Box>

          <Box sx={{ mt: 4, mx: -4 }}>
            {[...Array(10)].map((item, index) => (
              <Box key={index} sx={{ my: 3, mt: index === 0 ? 0 : undefined }}>
                <PublicationCard
                  PublicationBookmarkButtonComponent={
                    <IconButton disabled size="small" style={{ padding: 2 }}>
                      <StarBorder sx={{ fontSize: 22 }} />
                    </IconButton>
                  }
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }

  if (!response?.hits?.length) {
    return (
      <Box sx={{ m: 4 }}>
        <Box sx={{ my: 4 }}>{AppliedFiltersComponent}</Box>
        <Box sx={{ textAlign: 'center', mt: 20 }}>{NoResultsComponent}</Box>
      </Box>
    );
  }

  const maxResults = Math.min(response.meta.total_hits, 1000);
  const page = pagination.offset / pagination.limit + 1;
  const pages = Math.ceil(maxResults / pagination.limit);
  const isLastPage = page === pages;

  function handleChange(event: ChangeEvent<unknown>, value: number, newLimit?: number) {
    if (!pagination) return;
    onPaginationChange?.({
      previousPage: page,
      newPage: value,
      totalPages: pages,
      newLimit,
      pagination: {
        ...pagination,
        offset: newLimit ? newLimit * (value - 1) : pagination.limit * (value - 1),
        limit: newLimit || pagination.limit,
      },
    });
  }

  const documentInEnglish = SearchUtils.documentInEnglish(response);
  const documentTranslated = SearchUtils.documentTranslated(response);
  const reachedTranslationLimit = SearchUtils.reachedTranslationLimit(response);
  const highlightedTextTruncated = SearchUtils.highlightedTextTruncated(response);

  return (
    <Box sx={{ mt: 4 }}>
      {!documentInEnglish && !user && !institution && (
        <Alert severity="warning" sx={{ m: 4, py: 0 }} icon={false}>
          {SignUpToGetEnglishArticlesComponent}
        </Alert>
      )}

      {!documentInEnglish && reachedTranslationLimit && !cookies[CROSS_LANGUAGE_ALERT_COOKIE] && (
        <Alert
          severity="warning"
          sx={{
            m: 4,
            py: 0,
            alignItems: 'center',
            '& .MuiAlert-action': { padding: 0 },
          }}
          icon={false}
          action={
            <IconButton
              size="small"
              onClick={() => {
                setCookie(CROSS_LANGUAGE_ALERT_COOKIE, 'true', {
                  path: '/',
                  expires: new Date(response.computed.translationUsage.expiresAt),
                });
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Typography variant="body2">
            Daily cross-language search limit reached.{' '}
            {Boolean(response.computed.translationUsage) && (
              <>Resets at {format(new Date(response.computed.translationUsage.expiresAt), 'p')}.</>
            )}{' '}
            {!isPlusUser && (
              <ExternalLink
                underline="always"
                href={`${config.WEBSITE_URL}${WebsiteNavigation.Plans}`}
                color="inherit"
                onClick={() =>
                  AnalyticsUtils.captureEvent('Landing: Clicked "Upgrade" Call To Action', {
                    location: 'cross-language-search-limit-reached',
                  })
                }
              >
                <strong>Upgrade to Plus for more!</strong>
              </ExternalLink>
            )}
          </Typography>
        </Alert>
      )}

      {highlightedTextTruncated && (
        <Alert severity="warning" sx={{ m: 4, py: 0 }} icon={false}>
          <Typography variant="body2">
            Analysed {response.computed.truncatedTexts?.[1]?.percentageAnalyzed}% of highlighted text. Upgrade for
            unlimited highlight text.{' '}
            {!isPlusUser && (
              <ExternalLink
                underline="always"
                href={`${config.WEBSITE_URL}${WebsiteNavigation.Plans}`}
                color="inherit"
                onClick={() =>
                  AnalyticsUtils.captureEvent('Landing: Clicked "Upgrade" Call To Action', {
                    location: 'highlight-text-limit-reached',
                  })
                }
              >
                <strong>Upgrade to Plus for more!</strong>
              </ExternalLink>
            )}
          </Typography>
        </Alert>
      )}

      {!documentInEnglish && documentTranslated && (
        <Alert
          icon={false}
          sx={{
            background: 'linear-gradient(270deg, #73E2FF 2.43%, #92FFC6 41.46%, #E2FFC2 100%)',
            m: 4,
            py: 0,
            borderRadius: 4,
            gap: 4,
          }}
        >
          <Typography variant="body2">
            <strong>Cross Language Search</strong> from{' '}
            <strong>{response.meta.query_texts[0].detected_language_name}</strong> to <strong>English</strong>
          </Typography>
        </Alert>
      )}

      <Box sx={{ mx: 4 }}>{AppliedFiltersComponent}</Box>

      <Box sx={{ mt: 4 }}>
        <Stack
          direction="row"
          sx={{ mx: 4, mb: 4, alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
        >
          <Tooltip
            title={
              !isPlusUser
                ? 'With Keenious Free your analysis is limited to the first 1000 words. Upgrade to Plus for unlimited analysis'
                : 'With Keenious Plus, you have unlimited document and highlight analysis'
            }
          >
            <Typography variant="caption" sx={{ gap: 2, mr: 4, display: 'block' }}>
              <strong>Based on {response?.computed.truncatedTexts[0].percentageAnalyzed}% of your document</strong>

              <InfoOutlined fontSize="small" sx={{ ml: 1, mb: -2, fontSize: '1rem' }} />
            </Typography>
          </Tooltip>

          {NumberOfResultsComponent}
        </Stack>

        <Box id="results">{children}</Box>
      </Box>

      <Box sx={{ pt: 4, pb: 12 }}>
        {!isLastPage && (
          <Stack direction="row" alignItems="center" sx={{ gap: 2, mx: 12, my: 4 }}>
            {page !== 1 && (
              <IconButton
                size="small"
                edge="start"
                disabled={!isPlusUser}
                onClick={() => handleChange(null, page - 1)}
                aria-label="previous page of results"
              >
                <ChevronLeft fontSize="small" />
              </IconButton>
            )}

            <CustomButton
              fullWidth
              disabled={!isPlusUser}
              onClick={() => handleChange(null, page + 1)}
              aria-label="next page of results"
              endIcon={<ChevronRight />}
            >
              Next Page
            </CustomButton>
          </Stack>
        )}

        <Box sx={{ margin: '0 auto' }}>
          <Stack direction="row" sx={{ gap: 4 }} alignItems="center" justifyContent="center">
            <PopupState variant="popover" popupId="filters-more-popover">
              {(popupState) => (
                <>
                  <Tooltip title="Results per page">
                    <Link
                      {...(isPlusUser && bindTrigger(popupState))}
                      underline="none"
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: isPlusUser ? 'inherit' : 'text.disabled',
                      }}
                    >
                      {pagination.limit}
                      <ArrowDropDown sx={{ ml: -2 }} />
                    </Link>
                  </Tooltip>

                  <Popover
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    sx={{
                      '& .MuiPopover-paper': {
                        maxWidth: 260,
                        borderRadius: 2,
                      },
                    }}
                    {...bindPopover(popupState)}
                  >
                    <List disablePadding>
                      {[10, 25, 50, 100].map((item) => (
                        <MenuItem
                          key={item}
                          value={item}
                          onClick={() => {
                            handleChange(null, Math.ceil((pagination.offset + 1) / item), item);
                            popupState.close();
                          }}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </List>
                  </Popover>
                </>
              )}
            </PopupState>

            <Typography>
              {pagination.offset + 1}-{pagination.offset + response.hits.length} of {maxResults}
            </Typography>
          </Stack>
        </Box>

        {!isPlusUser && <Box sx={{ textAlign: 'center', mt: 4, mx: 4 }}>{PaginationSignUpComponent}</Box>}
      </Box>
    </Box>
  );
};
