import { Box, Grid, Typography } from '@mui/material';

import { AnalyticsUtils } from '../../lib/analytics';
import { useFiltersStore } from '../../store/useFiltersStore';
import { FilledTextField } from '../new/FilledTextField';

interface Props {
  onEnter: () => void;
}

export function CitationCountFilter({ onEnter }: Props) {
  const filters = useFiltersStore((state) => state.filters);
  const updateFilters = useFiltersStore((state) => state.updateFilters);

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onEnter();
    }
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="body2" gutterBottom>
        <strong>Citation Count</strong>
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <FilledTextField
            label="Minimum"
            type="tel"
            size="small"
            placeholder="No limit"
            value={filters.cited_by_count_min}
            onChange={(event) => {
              const min = Number(event.target.value);
              const max = filters.cited_by_count_max;
              updateFilters({ cited_by_count_min: min });
              AnalyticsUtils.captureEvent('Filters: Changed "Citation Count" Filter', { min, max });
            }}
            onKeyDown={handleKeyDown}
            inputProps={{ min: 0, max: filters.cited_by_count_max }}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <FilledTextField
            label="Maximum"
            type="tel"
            size="small"
            placeholder="No limit"
            value={filters.cited_by_count_max}
            onChange={(event) => {
              const min = filters.cited_by_count_min;
              const max = Number(event.target.value);
              updateFilters({ cited_by_count_max: max });
              AnalyticsUtils.captureEvent('Filters: Changed "Citation Count" Filter', { min, max });
            }}
            onKeyDown={handleKeyDown}
            inputProps={{ min: filters.cited_by_count_min, max: 1e7 }}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}
