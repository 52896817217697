import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { AnalyticsUtils } from '../../../lib/analytics';
import { Color } from '../../../lib/colors';
import { TopicWithTypeEntity } from '../../../lib/excover';
import { LocalStorageUtils } from '../../../utils/LocalStorageUtils';
import { Card } from '../../card/Card/Card';
import { CardContent } from '../../card/CardContent/CardContent';

const COLLAPSED_DESCRIPTION_HEIGHT = 586;
// const COLLAPSED_DESCRIPTION_HEIGHT = 86;

interface IProps {
  topic: TopicWithTypeEntity;
  TopicHeaderComponent: React.ReactNode;
}

export const TopicPage: React.FC<IProps> = ({ topic, TopicHeaderComponent }) => {
  // const { data: preview } = useGetTopicPreview(TopicUtils.getWikipediaTopicTitle(topic));

  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!topic) return;

    const visitedTopics = LocalStorageUtils.get<string[]>('visitedTopics') || [];
    const visited = visitedTopics.includes(topic.id);
    if (!visited) {
      LocalStorageUtils.set('visitedTopics', [...visitedTopics, topic.id]);
    }

    AnalyticsUtils.captureEvent('Topics: Opened Topic Page', { name: topic.display_name, type: topic.type });
  }, [topic]);

  useEffect(() => {
    if (!descriptionRef.current?.offsetHeight) return;
    setDescriptionHeight(descriptionRef.current.offsetHeight);
    // }, [topic, descriptionRef.current, preview]);
  }, [topic, descriptionRef.current]);

  return (
    <Card
      data-testid={'TopicCard'}
      sx={{
        overflow: 'auto',
        m: 0,
        borderRadius: 0,
        boxShadow: 'none',
        border: 'none',
        '& .MuiLink-root': { '&:visited': { color: Color.VisitedLink } },
      }}
    >
      {TopicHeaderComponent}

      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        {!descriptionHeight && (
          <Typography
            variant="body2"
            component="div"
            sx={{ letterSpacing: 0.25, ':first-letter': { textTransform: 'capitalize' } }}
            display="block"
            className="ph-no-capture"
            ref={descriptionRef}
            // dangerouslySetInnerHTML={{ __html: preview?.extract_html || topic?.description }}
            dangerouslySetInnerHTML={{ __html: topic?.description }}
          />
        )}

        <Collapse
          in={descriptionExpanded || descriptionHeight <= COLLAPSED_DESCRIPTION_HEIGHT}
          collapsedSize={
            !topic || descriptionHeight > COLLAPSED_DESCRIPTION_HEIGHT
              ? COLLAPSED_DESCRIPTION_HEIGHT
              : descriptionHeight
          }
        >
          <Typography
            variant="body2"
            component="div"
            sx={{ letterSpacing: 0.25, ':first-letter': { textTransform: 'capitalize' } }}
            display="block"
            className="ph-no-ca pture"
            // dangerouslySetInnerHTML={{ __html: preview?.extract_html || topic?.description }}
            dangerouslySetInnerHTML={{ __html: topic?.description }}
          />
        </Collapse>

        {(!topic || descriptionHeight > COLLAPSED_DESCRIPTION_HEIGHT) && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              size="small"
              color="inherit"
              sx={{ borderRadius: '20px', fontSize: 13, pl: 4, mt: 2, mb: 4 }}
              onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              aria-label={!descriptionExpanded ? 'Expand Description' : 'Collapse Description'}
            >
              {!descriptionExpanded ? (
                <>
                  Expand Description <ExpandMore fontSize="small" />
                </>
              ) : (
                <>
                  Collapse Description <ExpandLess fontSize="small" />
                </>
              )}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
