import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode, createContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { config } from './config';
import { FiltersDto, SourceTypeEnum, TopicType, WorkTypeEnum } from './excover';
import { InstitutionEntity, UserEntity } from './types';

type Environment = 'website' | 'addon-web' | 'addon-gdoc' | 'addon-word';
let environment: Environment;

type Events = {
  'Global: App Loaded': void;

  'Tabs: Clicked "Articles" Tab': void;
  'Tabs: Clicked "Topics" Tab': void;

  'Filters: Cleared Filters': void;
  'Filters: Added Topic to "Include List"': { name: string; type: TopicType };
  'Filters: Removed Topic from "Include List"': { name: string; type: TopicType };
  'Filters: Added Topic to "Exclude List"': { name: string; type: TopicType };
  'Filters: Removed Topic from "Exclude List"': { name: string; type: TopicType };
  'Filters: Changed "Year Published" Filter': { min: number; max: number };
  'Filters: Changed "Citation Count" Filter': { min: number; max: number };
  'Filters: Changed "Open Access Only" Filter': { value: boolean };

  'Search: Searched Articles': FiltersDto & {
    document_length: number;
    highlighted_length: number;
    keywords_length: number;
    articles_length: number;
    document_language: string;
  };
  'Search: Searched Topics': {
    document_length: number;
    keywords_length: number;
    fields_length: number;
    subfields_length: number;
    topics_length: number;
  };

  'Pagination: Clicked "Next Page" Button': { page: number };
  'Pagination: Changed "Results Per Page" Setting': { limit: number };

  'Citations: Clicked "Cite Publication" Button': void;
  'Citations: Changed Citation Style': { style: string };
  'Citations: Copied Citations To Clipboard': { style: string; works_length: number };
  'Citations: Downloaded Citations': { style: string; works_length: number };

  'Articles: Clicked "Export Result Page" Button': void;
  'Articles: Clicked "Bookmark Result Page" Button': void;
  'Articles: Clicked "Cross Language" Toggle': { value: boolean };
  'Articles: Opened Article Page': {
    work_type: WorkTypeEnum;
    source_name: string;
    source_type: SourceTypeEnum;
    language: string;
    publication_year: number;
    fields: string[];
  };
  'Articles: Clicked "Result Insights" Button': void;
  'Articles: Clicked DOI Link': void;
  'Articles: Clicked PDF Link': void;
  'Articles: Clicked Article Link': void;
  'Articles: Clicked Link Resolver Button': { institution?: string };
  'Articles: Scrolled Down To "Similar Articles"': void;
  'Articles: Clicked "See More" on "Similar Articles"': void;
  'Articles: Expanded Abstract': void;

  'Bookmarks: Clicked "Bookmarks" Filter Button': void;
  'Bookmarks: Added Bookmark': void;
  'Bookmarks: Removed Bookmark': void;

  'Topics: Viewed Topic Preview': { name: string; type: TopicType };
  'Topics: Opened Topic Page': { name: string; type: TopicType };

  'Auth: Clicked "Sign In" Button': void;
  'Auth: Clicked "Sign Up" Button': void;
  'Auth: Signed In': void;
  'Auth: Signed Out': void;
  'Auth: Signed Up': void;

  'Addon: Clicked "Explore Using Text" Button': void;
  'Addon: Clicked "Explore Using PDF" Button': void;

  'Landing: Clicked "Get Started" Call To Action': { location: string };
  'Landing: Clicked "Upgrade" Call To Action': { location: string };
  'Landing: Clicked Feature Step': { step: number };
  'Landing: Clicked FAQ Accordion': { location: string; id: string; question: string; order: number };
  'Landing: Clicked "Microsoft Word" Download Button': void;
  'Landing: Clicked "Google Docs" Download Button': void;
  'Landing: Scrolled To': { section: string };
};

const AnalyticsContext = createContext<undefined>(undefined);

interface AnalyticsProviderProps {
  environment: Environment;
  children: ReactNode;
}

const shouldCaptureEvents = config.NODE_ENV === 'production';

export function AnalyticsProvider({ environment: contextEnvironment, children }: AnalyticsProviderProps) {
  // Set the global environment
  environment = contextEnvironment;

  useEffect(() => {
    if (!shouldCaptureEvents) return;
    posthog.init(config.POSTHOG_KEY, { api_host: `${config.WEBSITE_URL}/ingest`, ui_host: 'https://eu.posthog.com' });
    AnalyticsUtils.captureEvent('Global: App Loaded');
  }, []);

  return (
    <AnalyticsContext.Provider value={undefined}>
      <PostHogProvider client={posthog}>{children}</PostHogProvider>
    </AnalyticsContext.Provider>
  );
}

export class AnalyticsUtils {
  /**
   * Capture an event with PostHog
   * @param event - The event name
   * @param data - Additional data to send with the event
   */
  static captureEvent<T extends keyof Events>(event: T, ...args: Events[T] extends void ? [] : [Events[T]]) {
    const payload = args[0] as Events[T];
    const properties = {
      ...(payload as object),
      environment,
    };

    if (!shouldCaptureEvents || !posthog.__loaded) {
      console.info('📈', event, properties);
      return;
    }

    posthog.capture(event, properties);
  }

  /**
   * Identify the user in PostHog for tracking
   * @param user - The user to set
   * @param institution - The user's institution
   */
  static identifyUser(user?: UserEntity, institution?: InstitutionEntity) {
    if (!shouldCaptureEvents || !posthog.__loaded) {
      console.info('🪪', user?.email, institution?.name);
      return;
    }

    const id = user?.email || posthog.get_distinct_id() || uuid();
    posthog.identify(id, {
      email: user?.email,
      ...(institution && { institution: institution.name }),
    });
  }
}
