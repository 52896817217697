import { ExpandLess, ExpandMore, VpnKey } from '@mui/icons-material';
import { Box, Button, Collapse, Skeleton, Tooltip, Typography } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { useGetWork } from '../../../hooks/data/works/useGetWork';
import { useUser } from '../../../hooks/features/useUser';
import { useEffectAsync } from '../../../hooks/useEffectAsync';
import { AnalyticsUtils } from '../../../lib/analytics';
import { Color } from '../../../lib/colors';
import { SourceTypeEnum, WorkEntity } from '../../../lib/excover';
import { LocalStorageUtils } from '../../../utils/LocalStorageUtils';
import { MathUtils } from '../../../utils/MathUtils';
import { WorkUtils } from '../../../utils/WorkUtils';
import { AuthorChip } from '../../authors/AuthorChip/AuthorChip';
import { Abstract } from '../../card/Abstract/Abstract';
import { Card } from '../../card/Card/Card';
import { CardContent } from '../../card/CardContent/CardContent';
import { ExternalLink } from '../../common/ExternalLink';
import { JournalChip } from '../../journals/JournalChip/JournalChip';
import { CustomButton } from '../../new/CustomButton';
import { PublicationHeader } from '../../publication/PublicationHeader/PublicationHeader';

const COLLAPSED_ABSTRACT_HEIGHT = 175;

function getUrl(url: string | undefined) {
  try {
    if (!url) return '';
    return new URL(url).host.replace('www.', '');
  } catch (e) {
    return '';
  }
}

export interface IPublicationPageProps {
  id: string;
  work?: WorkEntity;
  onLoad?: (response: WorkEntity) => Promise<void>;
  keywords?: string;
  PublicationBookmarkButtonComponent?: React.ReactNode;
  children?: ReactNode;
}

export const PublicationPage: React.FC<IPublicationPageProps> = ({
  id,
  work,
  onLoad,
  keywords,
  PublicationBookmarkButtonComponent,
  children,
}) => {
  const { institution, linkResolverBaseUrl } = useUser();

  const { data: publication } = useGetWork(id);

  const [abstractExpanded, setAbstractExpanded] = useState(false);
  const [abstractHeight, setAbstractHeight] = useState(0);
  const abstractRef = useRef<HTMLSpanElement>(null);

  useEffectAsync(async () => {
    if (!publication) return;

    onLoad && onLoad(publication);

    const visitedPublications = LocalStorageUtils.get<string[]>('visitedPublications') || [];
    const visited = visitedPublications.includes(publication.id);
    if (!visited) {
      LocalStorageUtils.set('visitedPublications', [...visitedPublications, publication.id]);
    }

    AnalyticsUtils.captureEvent('Articles: Opened Article Page', {
      work_type: publication.type,
      source_name: publication.primary_location.source.display_name,
      source_type: publication.primary_location.source.type,
      language: publication.language,
      publication_year: publication.publication_year,
      fields: publication.fields.map((field) => field.display_name),
    });
  }, [publication]);

  useEffect(() => {
    if (!abstractRef.current?.offsetHeight) return;
    setAbstractHeight(abstractRef.current.offsetHeight);
  }, [work, abstractRef.current]);

  const linkResolverUrl = work ? WorkUtils.getUrl(work, linkResolverBaseUrl) : WorkUtils.DEFAULT_LINK_RESOLVER;

  const pdfLink = work ? WorkUtils.getTitleLinkUrl(work) : '';

  return (
    <Card
      data-testid={'SearchResultCard'}
      sx={{
        overflow: 'auto',
        m: 0,
        borderRadius: 0,
        boxShadow: 'none',
        border: 'none',
        '& .MuiLink-root': { '&:visited': { color: Color.VisitedLink } },
      }}
    >
      <PublicationHeader
        work={work}
        keywords={keywords}
        onCitePublicationClick={() => AnalyticsUtils.captureEvent('Citations: Clicked "Cite Publication" Button')}
        PublicationBookmarkButtonComponent={PublicationBookmarkButtonComponent}
      />

      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <Box sx={{ mb: 4 }}>
          {(!work || work?.primary_location?.source?.type === SourceTypeEnum.JOURNAL) && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }} className="ph-no-capture">
              <JournalChip
                label={
                  work ? (
                    work.primary_location.source.display_name
                  ) : (
                    <Skeleton width={MathUtils.getRandomBetween(70, 120)} />
                  )
                }
                size="small"
                sx={{ m: 1, pl: 1 }}
              />
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center' }} className="ph-no-capture">
            {work ? (
              work.authorships
                .slice(0, 2)
                .map((authorship) => (
                  <AuthorChip
                    key={authorship.author.id + 'top'}
                    label={authorship.author.display_name}
                    size="small"
                    sx={{ m: 1, pl: 1 }}
                  />
                ))
            ) : (
              <AuthorChip
                label={<Skeleton width={MathUtils.getRandomBetween(70, 120)} />}
                size="small"
                sx={{ m: 1, pl: 1 }}
              />
            )}
            {work && work.authorships.length > 2 && (
              <Typography variant="caption" display="inline" sx={{ m: 1 }}>
                +{work.authorships.length - 2}
              </Typography>
            )}
          </Box>
        </Box>

        {(!work || work.abstract) && (
          <Typography variant="overline" sx={{ letterSpacing: 1.25, lineHeight: 1 }} color="secondary">
            Abstract
          </Typography>
        )}

        {work && !abstractHeight && (
          <Box ref={abstractRef}>
            <Abstract abstract={work.abstract} keywords={keywords || ''} />
          </Box>
        )}

        <Collapse
          in={abstractExpanded}
          collapsedSize={
            !work || abstractHeight > COLLAPSED_ABSTRACT_HEIGHT ? COLLAPSED_ABSTRACT_HEIGHT : abstractHeight
          }
        >
          <Typography
            variant="body2"
            sx={{ letterSpacing: 0.25 }}
            display="block"
            className="ph-no-capture"
            component="div"
          >
            {work ? (
              <Abstract abstract={work.abstract} keywords={keywords || ''} />
            ) : (
              <Skeleton height={COLLAPSED_ABSTRACT_HEIGHT} sx={{ transform: 'scale(1)' }} />
            )}
          </Typography>
        </Collapse>

        {(!work || abstractHeight > COLLAPSED_ABSTRACT_HEIGHT) && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              size="small"
              color="inherit"
              sx={{ borderRadius: '20px', fontSize: 13, pl: 4, mt: 2, mb: 4 }}
              onClick={() => {
                setAbstractExpanded(!abstractExpanded);
                AnalyticsUtils.captureEvent('Articles: Expanded Abstract');
              }}
              aria-label={!abstractExpanded ? 'Expand abstract' : 'Collapse abstract'}
            >
              {!abstractExpanded ? (
                <>
                  Expand Abstract <ExpandMore fontSize="small" />
                </>
              ) : (
                <>
                  Collapse Abstract <ExpandLess fontSize="small" />
                </>
              )}
            </Button>
          </Box>
        )}

        {Boolean(linkResolverUrl && (!work || work.doi)) && (
          <Box sx={{ mt: 3, mb: 6 }}>
            <Tooltip title="Click to see if your institution provides access to this resource">
              <span>
                <CustomButton
                  startIcon={<VpnKey />}
                  size="small"
                  disabled={!work}
                  href={linkResolverUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    AnalyticsUtils.captureEvent('Articles: Clicked Link Resolver Button', {
                      institution: institution?.name,
                    })
                  }
                  aria-label="access full text of this article"
                >
                  {institution?.abbreviation ? `Check ${institution?.abbreviation} Access` : 'Access Full-Text'}
                </CustomButton>
              </span>
            </Tooltip>
          </Box>
        )}

        <Typography variant="overline" sx={{ letterSpacing: 1.25, lineHeight: 1 }} color="secondary">
          Cited By
        </Typography>

        <Typography variant="caption" sx={{ letterSpacing: 0.25, mb: 3 }} display="block">
          {work ? work.cited_by_count : <Skeleton width={MathUtils.getRandomBetween(10, 50)} />}
        </Typography>

        {(!work || work.doi) && (
          <>
            <Typography variant="overline" sx={{ letterSpacing: 1.25, lineHeight: 1 }} color="secondary">
              DOI
            </Typography>

            <Typography variant="caption" sx={{ letterSpacing: 0.25, mb: 3 }} display="block" className="ph-no-capture">
              {work ? (
                <ExternalLink
                  href={work.doi}
                  title="DOI Link"
                  onClick={() => AnalyticsUtils.captureEvent('Articles: Clicked DOI Link')}
                >
                  {work.doi.replace('https://doi.org/', '')}
                </ExternalLink>
              ) : (
                <Skeleton width={MathUtils.getRandomBetween(100, 150)} />
              )}
            </Typography>
          </>
        )}

        {(!work || WorkUtils.hasPdf(work)) && (
          <>
            <Typography variant="overline" sx={{ letterSpacing: 1.25, lineHeight: 1 }} color="secondary">
              PDF
            </Typography>

            <Typography variant="caption" sx={{ letterSpacing: 0.25, mb: 3 }} display="block" className="ph-no-capture">
              {work ? (
                <ExternalLink
                  href={pdfLink || ''}
                  title="URL to PDF"
                  onClick={() => AnalyticsUtils.captureEvent('Articles: Clicked PDF Link')}
                >
                  {getUrl(pdfLink || '')}
                </ExternalLink>
              ) : (
                <Skeleton width={MathUtils.getRandomBetween(100, 150)} />
              )}
            </Typography>
          </>
        )}

        {(!work || work.open_access.oa_url) && (
          <>
            <Typography variant="overline" sx={{ letterSpacing: 1.25, lineHeight: 1 }} color="secondary">
              Links
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }} className="ph-no-capture">
              {work ? (
                <Typography variant="caption" sx={{ letterSpacing: 0.25, mr: 5 }}>
                  <ExternalLink
                    href={work.open_access.oa_url}
                    title="URL to Paper"
                    onClick={() => AnalyticsUtils.captureEvent('Articles: Clicked Article Link')}
                  >
                    {getUrl(work.open_access.oa_url)}
                  </ExternalLink>
                </Typography>
              ) : (
                <>
                  <Skeleton width={MathUtils.getRandomBetween(100, 150)} />
                </>
              )}
            </Box>
          </>
        )}

        {children}
      </CardContent>
    </Card>
  );
};
