import { ExcoverQueryEntity, ExcoverQueryTextTagEnum } from './excover';

// Blog Posts
export type BlogPostEntity = {
  id: string;
  title: string;
  link: string;
  publishedDate: string;
};

// Bookmarks
export type BookmarkEntity = {
  id: string;
  workId: string;
  // DateTime
  createdAt: Date;
  // User
  user?: UserEntity;
  userId: string;
};

// Institutions
export type InstitutionIpRangeEntity = {
  id: string;
  start: string;
  end: string;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // Institution
  institution?: InstitutionEntity;
  institutionId: string;
};

export enum IdentityProvider {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
  SAML = 'SAML',
}

export type InstitutionSettingsEntity = {
  id: string;
  // Settings
  linkResolverBaseUrl: string | null;
  twoFactorEnabled: boolean;
  // Providers
  providers: IdentityProvider[];
  samlEntryPoint?: string;
  samlCertificate?: string;
  // Institution
  institution?: InstitutionEntity;
  institutionId: string;
};

export type InstitutionEntity = {
  id: string;
  name: string;
  abbreviation: string;
  welcomeMessage: string;
  externalReferenceId: string;
  domains: string[];
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // Settings
  settings?: InstitutionSettingsEntity | null;
  ipRanges?: InstitutionIpRangeEntity[];
  users?: UserEntity[];
  temporaryUsers?: UserEntity[];
  invites?: TokenEntity[];
};

// Tokens
export enum TokenType {
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  INVITE = 'INVITE',
}

export type TokenEntity = {
  id: string;
  type: TokenType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any>;
  email: string;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  expiresAt: Date;
  // User
  user?: UserEntity | null;
  userId: string | null;
  // Team
  team?: TeamEntity | null;
  teamId: string | null;
  // Institution
  institution?: InstitutionEntity | null;
  institutionId: string | null;
};

// Users
export enum UserRole {
  GUEST = 'GUEST',
  USER = 'USER',
  TEAM_ADMIN = 'TEAM_ADMIN',
  INSTITUTION_ADMIN = 'INSTITUTION_ADMIN',
  KEENIOUS_ADMIN = 'KEENIOUS_ADMIN',
}

export type UserEntity = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  role: UserRole;
  // Providers
  providers: IdentityProvider[];
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // Settings
  settings?: UserSettingsEntity | null;
  // Subscription
  subscription?: IndividualSubscriptionEntity | null;
  // Team
  team?: TeamEntity | null;
  teamId: string | null;
  // Institution
  institution?: InstitutionEntity | null;
  institutionId: string | null;
  // Temporary Institution
  temporaryInstitution?: InstitutionEntity | null;
  temporaryInstitutionId: string | null;
  // Two Factor Authentication
  twoFactorAuthentication?: TwoFactorAuthenticationEntity | null;
};

export type TwoFactorAuthenticationEntity = {
  id: string;
  secret?: string;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // User
  user?: UserEntity;
  userId: string;
};

export type UserSettingsEntity = {
  id: string;
  // Settings
  linkResolverBaseUrl: string | null;
  crossLanguageEnabled: boolean;
  // User
  user?: UserEntity;
  userId: string;
};

export type IndividualSubscriptionEntity = {
  id: string;
  // Stripe
  customerId: string | null;
  productId: string | null;
  priceId: string | null;
  subscriptionId: string | null;
  subscriptionStatus: StripeSubscriptionStatusEnum | null;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  cancellationDate: Date | null;
  // User
  user?: UserEntity;
  userId: string;
};

export type TeamEntity = {
  id: string;
  name: string;
  externalReferenceId: string;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // Users
  users?: UserEntity[];
  // Invites
  invites?: TokenEntity[];
  // Subscription
  subscription?: TeamSubscriptionEntity;
};

export type TeamSubscriptionEntity = {
  id: string;
  // Stripe
  customerId: string | null;
  productId: string | null;
  priceId: string | null;
  subscriptionId: string | null;
  subscriptionStatus: string | null;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  cancellationDate: Date | null;
  // Team
  team?: TeamEntity;
  teamId: string;
};

// Pagination
export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

// Citations
export enum CitationStyleEnum {
  APA7th = 'citation-apa',
  APA6th = 'citation-apa-6',
  MLA = 'citation-mla',
  Chicago = 'citation-chicago',
  Harvard = 'citation-harvard1',
  Vancouver = 'citation-vancouver',
  BibTeX = 'bibtex',
  RIS = 'ris',
  CSL = 'csl',
}

// OpenAI
export type CompletionDto = {
  id?: string;
  prompt: string;
  response?: string;
  temperature?: number;
  sessionId?: string;
};

export type AIAdvisorInteractionEntity = {
  id: string;
  prompt: string;
  response: string;
  temperature: number;
  sessionId: string;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // User
  user?: UserEntity;
  userId: string;
};

export type TranslationUsageEntity = {
  userId?: string;
  uses: number;
  limit: number;
  expiresAt: Date | null;
};

export type TruncatedTextEntity = {
  wordCount: number;
  wordLimit: number;
  percentageAnalyzed: number;
  truncatedText?: string;
  tag: ExcoverQueryTextTagEnum;
};

export type SearchEntity = ExcoverQueryEntity & {
  computed: {
    translationUsage?: TranslationUsageEntity;
    truncatedTexts?: TruncatedTextEntity[];
  };
};

export enum StripeSubscriptionStatusEnum {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELLED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  PAUSED = 'paused',
}

export type StripePriceEntity = {
  id: string;
  nickname: StripePriceNicknameEnum;
  currency: string;
  amount: number;
  interval: StripePriceIntervalEnum;
  intervalCount: number;
};

export type StripeProductEntity = {
  id: string;
  name: string;
  prices: StripePriceEntity[];
};

export enum StripePriceIntervalEnum {
  MONTH = 'month',
  YEAR = 'year',
}

export enum StripePriceNicknameEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export type ChangePricePreviewEntity = {
  currency: string;
  amountDue: number;
  periodEnd: number;
};

export type CurrencyEntity = {
  code: string;
  symbol: string;
};

export type PlanEntity = {
  product: StripeProductEntity;
  price: StripePriceEntity;
};
