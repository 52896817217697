import { Box, Chip, Grid, Typography } from '@mui/material';

import { AnalyticsUtils } from '../../lib/analytics';
import { initialFilters, useFiltersStore } from '../../store/useFiltersStore';
import { FilledTextField } from '../new/FilledTextField';

interface IProps {
  onEnter: () => void;
}

export const YearFilter: React.FC<IProps> = ({ onEnter }) => {
  const filters = useFiltersStore((state) => state.filters);
  const updateFilters = useFiltersStore((state) => state.updateFilters);

  const chipFilters = [
    { label: 'Anytime', min: initialFilters.publication_year_min, max: initialFilters.publication_year_max },
    {
      label: `Since ${initialFilters.publication_year_max - 1}`,
      min: initialFilters.publication_year_max - 1,
      max: initialFilters.publication_year_max,
    },
    {
      label: `Since ${initialFilters.publication_year_max - 3}`,
      min: initialFilters.publication_year_max - 3,
      max: initialFilters.publication_year_max,
    },
    {
      label: `Since ${initialFilters.publication_year_max - 5}`,
      min: initialFilters.publication_year_max - 5,
      max: initialFilters.publication_year_max,
    },
  ];

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onEnter();
    }
  }

  return (
    <Box sx={{ p: 4, overflow: 'hidden' }}>
      <Typography variant="body2" gutterBottom>
        <strong>Year Published</strong>
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <FilledTextField
            label="From"
            size="small"
            type="number"
            value={filters.publication_year_min.toString()}
            onChange={(event) => {
              const min = Number(event.target.value);
              const max = filters.publication_year_max;
              updateFilters({ publication_year_min: min });
              AnalyticsUtils.captureEvent('Filters: Changed "Year Published" Filter', { min, max });
            }}
            onKeyDown={handleKeyDown}
            inputProps={{ min: initialFilters.publication_year_min, max: filters.publication_year_max }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FilledTextField
            label="To"
            size="small"
            type="number"
            value={filters.publication_year_max.toString()}
            onChange={(event) => {
              const min = filters.publication_year_min;
              const max = Number(event.target.value);
              updateFilters({ publication_year_max: max });
              AnalyticsUtils.captureEvent('Filters: Changed "Year Published" Filter', { min, max });
            }}
            onKeyDown={handleKeyDown}
            inputProps={{ min: filters.publication_year_min, max: initialFilters.publication_year_max }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Box sx={{ pt: 4, mx: 0 }}>
        <Grid container spacing={1}>
          {chipFilters.map((chipFilter) => (
            <Grid key={chipFilter.min} item>
              <Chip
                size="small"
                label={chipFilter.label}
                variant={
                  filters.publication_year_min === chipFilter.min && filters.publication_year_max === chipFilter.max
                    ? 'filled'
                    : 'outlined'
                }
                onClick={() => {
                  const min = chipFilter.min;
                  const max = chipFilter.max;
                  updateFilters({ publication_year_min: min, publication_year_max: max });
                  AnalyticsUtils.captureEvent('Filters: Changed "Year Published" Filter', { min, max });
                  onEnter();
                }}
                style={{ fontSize: 11, border: '1px solid rgba(0, 0, 0, 0.23)' }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
