import { config } from './config';

export enum WebsiteNavigation {
  Home = '/',
  Landing = '/?landing',
  About = '/about',
  Careers = '/careers',
  Contact = '/contact',
  ForStudents = '/for/students',
  ForResearchers = '/for/researchers',
  ForLibrarians = '/for/librarians',
  UniversitiesOverview = '/universities',
  UniversitiesCaseStudies = '/universities/case-studies',
  UniversitiesContactSales = '/universities/contact-sales',
  Research = '/research',
  Blog = '/blog',
  CaseStudies = '/case-studies',
  HelpCenter = '/help-center',
  Download = '/download',
  Plans = '/plans',
  Upgrade = '/upgrade',

  // Search
  Explore = '/explore',
  ExploreText = '/explore/text',
  ExplorePdf = '/explore/pdf',
  Demo = '/demo',
  DemoText = '/demo/text',
  DemoPdf = '/demo/pdf',

  // Auth
  SignIn = '/auth/sign-in',
  SignUp = '/auth/sign-up',
  SignOut = '/auth/sign-out',
  SignInApprove = '/auth/sign-in/approve',
  VerifyEmail = '/auth/verify-email',

  // User
  Settings = '/settings',
  Plan = '/plan',

  // Institution
  Institution = '/institution',
  InstitutionMembers = '/institution/members',
  InstitutionInvitations = '/institution/invitations',
  InstitutionSettings = '/institution/settings',

  // Admin
  Admin = '/admin',
  AdminInstitutions = '/admin/institutions',
  AdminTeams = '/admin/teams',
  AdminUsers = '/admin/users',
  AdminNewsletter = '/admin/newsletter',

  // Legal
  TermsOfUse = '/legal/terms-of-use',
  PrivacyPolicy = '/legal/privacy-policy',
  CookiePolicy = '/legal/cookie-policy',
}

export const AddonNavigation = {
  Home: `${config.ADDON_BASE_PATH}`,
  Result: `${config.ADDON_BASE_PATH}result`,
  Topics: `${config.ADDON_BASE_PATH}topics`,
};

export enum ExternalNavigation {
  MicrosoftWordAddIn = 'https://appsource.microsoft.com/en-us/product/office/WA200000086?src=keeniouscom&mktcmpid=websitecmp1',
  GoogleDocsAddOn = 'https://workspace.google.com/marketplace/app/keenious/326094023095',
  HowToSearch = 'https://help.keenious.com/article/35-how-to-search-with-keenious',
  GoogleDocsAuthorizationError = 'https://help.keenious.com/article/46-google-docs-error-authorization-is-required-to-perform-that-action',
  HowKeeniousRecommendsArticles = 'https://help.keenious.com/article/54-how-keenious-recommends-research-articles',
  ResultInsights = 'https://help.keenious.com/article/68-result-insights',
  Instagram = 'https://www.instagram.com/keenious',
  Facebook = 'https://www.facebook.com/keenious.startup',
  X = 'https://x.com/keenious',
  YouTube = 'https://www.youtube.com/channel/UC7S06fcCvVMPnf0-hEzBMVQ',
  LinkedIn = 'https://www.linkedin.com/company/keenious',
  Careers = 'https://keenious.talentlyft.com',
  Blog = 'https://medium.com/@keenious',
  HelpCenter = 'https://help.keenious.com',
}
