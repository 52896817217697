import { ArrowForward, Close, FileDownload, MoreVert, Star, StarBorder, Stars } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
  darken,
} from '@mui/material';
import { format } from 'date-fns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

import { CitationDialog } from '@keenious/libraries/components/citations/CitationDialog/CitationDialog';
import { ExternalLink } from '@keenious/libraries/components/common/ExternalLink';
import { AppliedFilters } from '@keenious/libraries/components/filters/AppliedFilters';
import { useUpdateUser } from '@keenious/libraries/hooks/data/auth/useUpdateUser';
import { useCreateBookmarks } from '@keenious/libraries/hooks/data/bookmarks/useCreateBookmarks';
import { useGetBookmarks } from '@keenious/libraries/hooks/data/bookmarks/useGetBookmarks';
import { useUser } from '@keenious/libraries/hooks/features/useUser';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { Color } from '@keenious/libraries/lib/colors';
import { config } from '@keenious/libraries/lib/config';
import { ExcoverQueryDto } from '@keenious/libraries/lib/excover';
import { WebsiteNavigation } from '@keenious/libraries/lib/navigation';
import { useBindDelayedHover } from '@keenious/libraries/lib/useBindDelayedHover';
import { initialFilters, useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useTopicsStore } from '@keenious/libraries/store/useTopicsStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { SearchUtils } from '@keenious/libraries/utils/SearchUtils';

import { useAddonSignIn } from '../../hooks/useAddonSignIn';
import { useEditorStore } from '../../store/useEditorStore';
import { useSearchStore } from '../../store/useSearchStore';
import { FiltersContainer } from '../filters/FiltersContainer';
import { TopicContainer } from '../topics/TopicContainer';

interface IProps {
  query: ExcoverQueryDto;
  children?: React.ReactNode;
}

export const AppliedFiltersContainer: React.FC<IProps> = ({ query }) => {
  const { user, institution, isPlusUser } = useUser();

  const { data: bookmarks } = useGetBookmarks();
  const createBookmarks = useCreateBookmarks();
  const updateUser = useUpdateUser();

  const loading = useUiStore((state) => state.loading);
  const setIgnoredHighlightedText = useEditorStore((state) => state.setIgnoredHighlightedText);
  const setHighlightedText = useEditorStore((state) => state.setHighlightedText);
  const filters = useFiltersStore((state) => state.filters);
  const updateFilters = useFiltersStore((state) => state.updateFilters);
  const setFilteredHighlightedText = useFiltersStore((state) => state.setHighlightedText);
  const getTopicById = useTopicsStore((state) => state.getTopicById);
  const searchResponse = useSearchStore((state) => state.searchResponse);
  const previousHighlightedText = useSearchStore((state) => state.previousHighlightedText);
  const applySearch = useSearchStore((state) => state.applySearch);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);

  const [citationDialogOpen, setCitationDialogOpen] = useState(false);
  const { signUpUrl, onSignUp } = useAddonSignIn();

  const reachedTranslationLimit = SearchUtils.reachedTranslationLimit(searchResponse);
  const documentInEnglish = SearchUtils.documentInEnglish(searchResponse);

  const popupState = usePopupState({ variant: 'popover', popupId: 'cross-language-popover' });
  const props = useBindDelayedHover(popupState);

  const CROSS_LANGUAGE_ENABLED_COOKIE = 'crossLanguageEnabled';
  const [cookies, setCookie] = useCookies([CROSS_LANGUAGE_ENABLED_COOKIE]);
  const crossLanguageEnabled = user
    ? user.settings.crossLanguageEnabled
    : cookies[CROSS_LANGUAGE_ENABLED_COOKIE] !== false;

  function toggleCrossLanguage() {
    AnalyticsUtils.captureEvent('Articles: Clicked "Cross Language" Toggle', { value: !crossLanguageEnabled });
    if (!user) {
      setCookie(CROSS_LANGUAGE_ENABLED_COOKIE, !crossLanguageEnabled, { path: '/' });
    } else {
      updateUser.mutate({
        ...user,
        settings: { ...user.settings, crossLanguageEnabled: !user.settings.crossLanguageEnabled },
      });
    }
  }

  return (
    <AppliedFilters
      query={query}
      onRemoveHighlightedText={() => {
        setFilteredHighlightedText('');
        setHighlightedText('');
        setIgnoredHighlightedText(previousHighlightedText);
        resetAndApplySearch();
      }}
      onRemoveDateRange={() => {
        updateFilters({
          publication_year_min: initialFilters.publication_year_min,
          publication_year_max: initialFilters.publication_year_max,
        });
        applySearch();
      }}
      onRemoveCitationCount={() => {
        updateFilters({
          cited_by_count_min: initialFilters.cited_by_count_min,
          cited_by_count_max: initialFilters.cited_by_count_max,
        });
        applySearch();
      }}
      onRemoveOpenAccess={() => {
        updateFilters({ is_oa: initialFilters.is_oa });
        applySearch();
      }}
      loading={loading}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Stack direction="row" spacing={{ xs: 0, sm: 2 }} alignItems="center" flexWrap="wrap">
          <FiltersContainer />

          <Tooltip
            title={
              !user
                ? 'Sign up to use bookmarks'
                : !bookmarks?.length
                  ? "You don't have any bookmarks. Bookmark an article for them to show up here"
                  : undefined
            }
          >
            <span>
              <Chip
                clickable
                disabled={loading || !user || !bookmarks?.length}
                icon={
                  filters.works_include_list.length ? (
                    <Star sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />
                  ) : (
                    <StarBorder sx={{ fontSize: '1rem !important', ml: '4px !important' }} />
                  )
                }
                deleteIcon={<></>}
                label="Bookmarks"
                size="small"
                variant={filters.works_include_list.length ? 'filled' : 'outlined'}
                sx={{
                  border: 'none',
                  ...(filters.works_include_list.length && {
                    color: `${Color.White} !important`,
                    backgroundColor: (theme) => `${theme.palette.warning.main} !important`,
                  }),
                }}
                onClick={() => {
                  updateFilters({
                    works_include_list: !filters.works_include_list.length
                      ? bookmarks.map((bookmark) => bookmark.workId)
                      : [],
                  });
                  AnalyticsUtils.captureEvent('Bookmarks: Clicked "Bookmarks" Filter Button');
                  resetAndApplySearch();
                }}
                {...(Boolean(filters.works_include_list.length) && {
                  onDelete: () => {
                    updateFilters({
                      works_include_list: !filters.works_include_list.length
                        ? bookmarks.map((bookmark) => bookmark.workId)
                        : [],
                    });
                    resetAndApplySearch();
                  },
                })}
              />
            </span>
          </Tooltip>

          <PopupState variant="popover" popupId="filters-more-popover">
            {(popupState) => (
              <>
                <IconButton {...bindTrigger(popupState)} disabled={loading} size="small">
                  <MoreVert sx={{ fontSize: '1rem', color: loading ? 'inherit' : Color.Text }} />
                </IconButton>

                <Popover
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  sx={{
                    '& .MuiPopover-paper': {
                      maxWidth: 260,
                      borderRadius: 2,
                    },
                  }}
                  {...bindPopover(popupState)}
                >
                  <List disablePadding>
                    <Tooltip title={!isPlusUser ? 'Upgrade to Plus to access this feature' : undefined}>
                      <span>
                        <ListItemButton
                          disabled={!isPlusUser}
                          sx={{ py: 3, px: 4 }}
                          onClick={() => {
                            setCitationDialogOpen(true);
                            AnalyticsUtils.captureEvent('Articles: Clicked "Export Result Page" Button');
                            popupState.close();
                          }}
                          aria-label="export results"
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}>
                            <FileDownload fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">Export result page</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </span>
                    </Tooltip>

                    <Tooltip title={!isPlusUser ? 'Upgrade to Plus to access this feature' : undefined}>
                      <span>
                        <ListItemButton
                          disabled={!isPlusUser}
                          sx={{ py: 3, px: 4 }}
                          onClick={() => {
                            createBookmarks.mutateAsync(searchResponse.hits.map((hit) => hit.entity.id));
                            AnalyticsUtils.captureEvent('Articles: Clicked "Bookmark Result Page" Button');
                            popupState.close();
                          }}
                          aria-label="export results"
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}>
                            <Stars fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">Bookmark result page</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </span>
                    </Tooltip>
                  </List>
                </Popover>
              </>
            )}
          </PopupState>

          {citationDialogOpen && (
            <CitationDialog
              works={searchResponse.hits.map((hit) => hit.entity)}
              open={citationDialogOpen}
              onClose={() => setCitationDialogOpen(false)}
            />
          )}
        </Stack>

        <FormControlLabel
          {...props}
          disableTypography
          control={
            <Switch
              disabled={!user || loading || reachedTranslationLimit}
              checked={crossLanguageEnabled && !reachedTranslationLimit}
              color="default"
              size="small"
              sx={{
                '& .Mui-checked.Mui-disabled': {
                  '&.MuiSwitch-switchBase': {
                    color: (theme) => theme.palette.text.disabled,
                  },
                },
                '& .Mui-checked:not(& .Mui-disabled)': {
                  '& + .MuiSwitch-track': {
                    background: darken('#73E2FF', 0.1),
                  },
                  '&.MuiSwitch-switchBase': {
                    color: darken('#73E2FF', 0.1),
                  },
                },
              }}
              onChange={toggleCrossLanguage}
            />
          }
          label={
            <Typography
              variant="caption"
              sx={{
                mr: 1,
                color: !user || loading || reachedTranslationLimit ? 'inherit' : Color.Text,
              }}
            >
              Cross Language
            </Typography>
          }
          labelPlacement="start"
          sx={{
            ml: 2,
            mr: '-4px',
            color: (theme) => theme.palette.text.secondary,
            '&.Mui-disabled': {
              color: (theme) => theme.palette.text.disabled,
            },
          }}
        />

        <HoverPopover
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            '& .MuiPopover-paper': {
              maxWidth: 260,
              borderRadius: 2,
              p: 6,
            },
          }}
          {...bindPopover(popupState)}
        >
          <Typography gutterBottom>
            <strong>Cross Language Search</strong>
          </Typography>

          <Typography variant="body2">
            Get results from a different language by having Keenious translate your document first.{' '}
            <ExternalLink
              color="secondary"
              underline="always"
              href="https://help.keenious.com/article/53-cross-language-recommendations"
            >
              Learn More
            </ExternalLink>
          </Typography>

          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" gap={4} sx={{ mt: 4, mb: 2 }}>
            <Typography variant="body2" sx={{ textAlign: 'center', flex: 1 }}>
              {documentInEnglish
                ? 'Detect language'
                : searchResponse?.meta.query_texts[0].detected_language_name || 'Detect language'}
            </Typography>

            <ArrowForward fontSize="small" />

            <Typography variant="body2" sx={{ textAlign: 'center', flex: 1 }}>
              English
            </Typography>
          </Stack>

          {searchResponse?.computed.translationUsage && (
            <>
              <Divider sx={{ my: 6 }} />

              {reachedTranslationLimit ? (
                <Typography variant="body2">
                  Daily cross-language search limit reached.{' '}
                  {Boolean(searchResponse.computed.translationUsage) && (
                    <>Resets at {format(new Date(searchResponse.computed.translationUsage.expiresAt), 'p')}.</>
                  )}{' '}
                  {!isPlusUser && (
                    <Typography variant="body2" component="span" sx={{ fontWeight: 600 }}>
                      <ExternalLink
                        color="secondary"
                        underline="always"
                        href={`${config.WEBSITE_URL}${WebsiteNavigation.Plans}`}
                        onClick={() =>
                          AnalyticsUtils.captureEvent('Landing: Clicked "Upgrade" Call To Action', {
                            location: 'cross-language-search-limit-reached',
                          })
                        }
                      >
                        Upgrade to Plus for more!
                      </ExternalLink>
                    </Typography>
                  )}
                </Typography>
              ) : (
                <Typography variant="body2">
                  You have{' '}
                  {searchResponse.computed.translationUsage.limit - searchResponse.computed.translationUsage.uses}{' '}
                  cross-language searches left for today.
                </Typography>
              )}
            </>
          )}

          {!user && !institution && (
            <>
              <Divider sx={{ my: 6 }} />

              <Typography variant="body2" sx={{ mt: 4 }}>
                <Typography variant="body2" component="span" sx={{ fontWeight: 600 }}>
                  <ExternalLink color="secondary" underline="always" href={signUpUrl} onClick={onSignUp}>
                    Sign up
                  </ExternalLink>
                </Typography>{' '}
                to get access to Cross Language Recommendations.
              </Typography>
            </>
          )}
        </HoverPopover>
      </Box>

      {filters.topics_include_list.map((topicId) => {
        const topic = getTopicById(topicId);
        if (!topic) return null;
        return (
          <TopicContainer
            key={topic.id}
            topic={topic}
            TopicChipProps={{
              sx: { m: 1 },
              deleteIcon: <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />,
              onClick: () => {
                updateFilters({ topics_include_list: filters.topics_include_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
              onDelete: () => {
                updateFilters({ topics_include_list: filters.topics_include_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
            }}
          />
        );
      })}

      {filters.fields_include_list.map((topicId) => {
        const topic = getTopicById(topicId);
        if (!topic) return null;
        return (
          <TopicContainer
            key={topic.id}
            topic={topic}
            TopicChipProps={{
              sx: { m: 1 },
              deleteIcon: <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />,
              onClick: () => {
                updateFilters({ fields_include_list: filters.fields_include_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
              onDelete: () => {
                updateFilters({ fields_include_list: filters.fields_include_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
            }}
          />
        );
      })}

      {filters.subfields_include_list.map((topicId) => {
        const topic = getTopicById(topicId);
        if (!topic) return null;
        return (
          <TopicContainer
            key={topic.id}
            topic={topic}
            TopicChipProps={{
              sx: { m: 1 },
              deleteIcon: <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />,
              onClick: () => {
                updateFilters({
                  subfields_include_list: filters.subfields_include_list.filter((id) => id !== topic.id),
                });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
              onDelete: () => {
                updateFilters({
                  subfields_include_list: filters.subfields_include_list.filter((id) => id !== topic.id),
                });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
            }}
          />
        );
      })}

      {filters.topics_exclude_list.map((topicId) => {
        const topic = getTopicById(topicId);
        if (!topic) return null;
        return (
          <TopicContainer
            key={topic.id}
            topic={topic}
            TopicChipProps={{
              sx: { m: 1 },
              deleteIcon: <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />,
              onClick: () => {
                updateFilters({ topics_exclude_list: filters.topics_exclude_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
              onDelete: () => {
                updateFilters({ topics_exclude_list: filters.topics_exclude_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
            }}
          />
        );
      })}

      {filters.fields_exclude_list.map((topicId) => {
        const topic = getTopicById(topicId);
        if (!topic) return null;
        return (
          <TopicContainer
            key={topic.id}
            topic={topic}
            TopicChipProps={{
              sx: { m: 1 },
              deleteIcon: <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />,
              onClick: () => {
                updateFilters({ fields_exclude_list: filters.fields_exclude_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
              onDelete: () => {
                updateFilters({ fields_exclude_list: filters.fields_exclude_list.filter((id) => id !== topic.id) });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
            }}
          />
        );
      })}

      {filters.subfields_exclude_list.map((topicId) => {
        const topic = getTopicById(topicId);
        if (!topic) return null;
        return (
          <TopicContainer
            key={topic.id}
            topic={topic}
            TopicChipProps={{
              sx: { m: 1 },
              deleteIcon: <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />,
              onClick: () => {
                updateFilters({
                  subfields_exclude_list: filters.subfields_exclude_list.filter((id) => id !== topic.id),
                });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
              onDelete: () => {
                updateFilters({
                  subfields_exclude_list: filters.subfields_exclude_list.filter((id) => id !== topic.id),
                });
                resetAndApplySearch();
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              },
            }}
          />
        );
      })}
    </AppliedFilters>
  );
};
