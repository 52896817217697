import { StripeSubscriptionStatusEnum, UserEntity, UserRole } from '../../lib/types';
import { useGetUser } from '../data/auth/useGetUser';
import { useGetInstitutionByIp } from '../data/institutions/useGetInstitutionByIp';

/**
 * Checks if the user has access to a resource based on their role and the minimum role required to access the resource
 * @param minimumRole - The minimum role required to access the resource
 * @returns true if the user has access to the resource and false otherwise
 */
export function hasAccess(user: UserEntity, minimumRole: UserRole): boolean {
  switch (minimumRole) {
    case UserRole.GUEST:
      return true;
    case UserRole.USER:
      return user?.role === UserRole.USER;
    case UserRole.INSTITUTION_ADMIN:
      return (
        user?.role === UserRole.USER ||
        user?.role === UserRole.INSTITUTION_ADMIN ||
        user?.role === UserRole.KEENIOUS_ADMIN
      );
    case UserRole.KEENIOUS_ADMIN:
      return user?.role === UserRole.KEENIOUS_ADMIN;
    default:
      return false;
  }
}

export function useUser() {
  const { data: user } = useGetUser();
  const { data: institutionByIp } = useGetInstitutionByIp();

  // Authorization
  const isGuestUser = !user;

  const isInstitutionUser = user
    ? Boolean(user.temporaryInstitutionId || user.institutionId)
    : Boolean(institutionByIp);

  const isInstitutionAdmin = user
    ? (user.temporaryInstitution && user.role === UserRole.KEENIOUS_ADMIN) ||
      (user.institution && user.role === UserRole.INSTITUTION_ADMIN)
    : false;

  const isKeeniousAdmin = user ? user.role === UserRole.KEENIOUS_ADMIN && !user.temporaryInstitution : false;

  // Subscription
  const hasActiveIndividualSubscription = user
    ? user.subscription?.subscriptionStatus === StripeSubscriptionStatusEnum.ACTIVE ||
      user.subscription?.subscriptionStatus === StripeSubscriptionStatusEnum.TRIALING
    : false;

  const hasPastDueIndividualSubscription = user
    ? user.subscription?.subscriptionStatus === StripeSubscriptionStatusEnum.PAST_DUE
    : false;

  const isFreeUser = user ? !hasActiveIndividualSubscription && !isInstitutionUser : false;

  const isPlusUser = user ? hasActiveIndividualSubscription || isInstitutionUser || isKeeniousAdmin : false;

  // Institution
  const institution = user ? user.temporaryInstitution || user.institution : institutionByIp;

  const linkResolverBaseUrl = user?.temporaryInstitution
    ? user.temporaryInstitution?.settings?.linkResolverBaseUrl || ''
    : user?.settings?.linkResolverBaseUrl || institution?.settings?.linkResolverBaseUrl || '';

  return {
    user,
    // Authorization
    isGuestUser,
    isInstitutionUser,
    isInstitutionAdmin,
    isKeeniousAdmin,
    // Subscription
    hasActiveIndividualSubscription,
    hasPastDueIndividualSubscription,
    isFreeUser,
    isPlusUser,
    // Institution
    institution,
    institutionByIp,
    linkResolverBaseUrl,
    // Functions
    hasAccess: (minimumRole: UserRole) => hasAccess(user, minimumRole),
  };
}
