import { Star, StarBorder } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useGetUser } from '@keenious/libraries/hooks/data/auth/useGetUser';
import { useCreateBookmarks } from '@keenious/libraries/hooks/data/bookmarks/useCreateBookmarks';
import { useDeleteBookmark } from '@keenious/libraries/hooks/data/bookmarks/useDeleteBookmark';
import { useGetBookmarks } from '@keenious/libraries/hooks/data/bookmarks/useGetBookmarks';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { WorkEntity } from '@keenious/libraries/lib/excover';
import { WorkUtils } from '@keenious/libraries/utils/WorkUtils';

import { useAddonSignIn } from '../../hooks/useAddonSignIn';

interface IProps {
  work?: WorkEntity;
}

export const PublicationBookmarkButton: React.FC<IProps> = ({ work }) => {
  const { data: user } = useGetUser();
  const { data: bookmarks } = useGetBookmarks();
  const createBookmarks = useCreateBookmarks();
  const deleteBookmark = useDeleteBookmark();

  const { onSignUp, signUpUrl } = useAddonSignIn();

  const bookmark = bookmarks?.find((bookmark) => bookmark.workId === WorkUtils.getId(work?.id));
  const bookmarked = Boolean(bookmark);

  async function handleClick() {
    if (deleteBookmark.isPending || createBookmarks.isPending) return;

    if (bookmark) {
      deleteBookmark.mutateAsync(bookmark);
      AnalyticsUtils.captureEvent('Bookmarks: Removed Bookmark');
    } else {
      createBookmarks.mutateAsync([work.id]);
      AnalyticsUtils.captureEvent('Bookmarks: Added Bookmark');
    }
  }

  if (!user || !work) {
    return (
      <Tooltip title="Sign up to use bookmarks">
        <IconButton
          component="a"
          size="small"
          style={{ padding: 2 }}
          aria-label="sign in to use bookmarks"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onSignUp}
          href={signUpUrl}
        >
          <StarBorder sx={{ fontSize: 22 }} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={bookmarked ? 'Remove from Bookmarks' : 'Add to Bookmarks'}>
      <IconButton
        size="small"
        style={{ padding: 2 }}
        aria-label={bookmarked ? 'remove from bookmarks' : 'add to bookmarks'}
        onClick={handleClick}
      >
        {bookmarked ? <Star color="warning" sx={{ fontSize: 22 }} /> : <StarBorder sx={{ fontSize: 22 }} />}
      </IconButton>
    </Tooltip>
  );
};
