import { Box, Skeleton, Typography } from '@mui/material';

import { ISearchResultsProps, SearchResults } from '@keenious/libraries/components/addon/search/SearchResults';
import { ExternalLink } from '@keenious/libraries/components/common/ExternalLink';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { config } from '@keenious/libraries/lib/config';
import { WebsiteNavigation } from '@keenious/libraries/lib/navigation';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { ISearchQueryLocalStorage } from '@keenious/libraries/types/excover';
import { LocalStorageUtils } from '@keenious/libraries/utils/LocalStorageUtils';

import { useAddonSignIn } from '../../hooks/useAddonSignIn';
import { Environment, environment } from '../../lib/environment';
import { useSearchStore } from '../../store/useSearchStore';
import { PublicationCardContainer } from '../publication/PublicationCardContainer';
import { AppliedFiltersContainer } from './AppliedFiltersContainer';

export const SearchResultsContainer: React.FC<Partial<ISearchResultsProps>> = (props) => {
  const { signUpUrl, onSignUp } = useAddonSignIn();

  const loading = useUiStore((state) => state.loading);
  const scrollToTop = useUiStore((state) => state.scrollToTop);
  const pagination = useFiltersStore((state) => state.pagination);
  const setPagination = useFiltersStore((state) => state.setPagination);
  const searchId = useSearchStore((state) => state.searchId);
  const searchResponse = useSearchStore((state) => state.searchResponse);
  const searchRequest = useSearchStore((state) => state.searchRequest);
  const applySearch = useSearchStore((state) => state.applySearch);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);

  const storage = LocalStorageUtils.get<ISearchQueryLocalStorage>(`search-${searchId}`);

  const NoResultsComponent = (
    <>
      <Typography variant="h4">No results found</Typography>
      <Typography>Sorry, we could not find any articles matching your filtering requirements.</Typography>
    </>
  );

  const NumberOfResultsComponent = (
    <Box>
      {!loading && searchResponse ? (
        <Typography variant="caption">
          {searchResponse.meta.total_hits > 1000
            ? `Top 1000${environment !== Environment.GoogleDocs ? ' results' : ''}`
            : `${searchResponse.meta.total_hits} result${searchResponse.meta.total_hits === 1 ? '' : 's'}`}
        </Typography>
      ) : (
        <Skeleton variant="text" width={80} height={20} />
      )}
    </Box>
  );

  const PaginationSignUpComponent = (
    <Typography variant="body2">
      Want more results?{' '}
      <ExternalLink
        href={`${config.WEBSITE_URL}${WebsiteNavigation.Plans}`}
        onClick={() =>
          AnalyticsUtils.captureEvent('Landing: Clicked "Upgrade" Call To Action', {
            location: 'pagination',
          })
        }
      >
        Upgrade to Keenious Plus
      </ExternalLink>{' '}
      now to get access to other relevant papers!
    </Typography>
  );

  const SignUpToGetEnglishArticlesComponent = (
    <Typography variant="body2">
      <ExternalLink onClick={onSignUp} href={signUpUrl} underline="always" color="inherit">
        <strong>Sign up</strong>
      </ExternalLink>{' '}
      to get English articles for your {searchResponse?.meta.query_texts[0].detected_language_name} document.
    </Typography>
  );

  return (
    <SearchResults
      loading={loading}
      request={searchRequest}
      response={searchResponse}
      pagination={pagination}
      onPaginationChange={({ pagination, newPage, newLimit }) => {
        const limitChanged = newLimit && pagination.limit !== newLimit;
        setPagination(pagination);
        if (limitChanged) {
          AnalyticsUtils.captureEvent('Pagination: Changed "Results Per Page" Setting', { limit: newLimit });
          resetAndApplySearch();
        } else {
          AnalyticsUtils.captureEvent('Pagination: Clicked "Next Page" Button', { page: newPage });
          applySearch();
        }
        scrollToTop();
      }}
      NoResultsComponent={NoResultsComponent}
      AppliedFiltersComponent={<AppliedFiltersContainer query={storage} />}
      PaginationSignUpComponent={PaginationSignUpComponent}
      NumberOfResultsComponent={NumberOfResultsComponent}
      SignUpToGetEnglishArticlesComponent={SignUpToGetEnglishArticlesComponent}
      {...props}
    >
      {searchResponse?.hits.map((hit) => {
        return <PublicationCardContainer key={hit.entity.id} work={hit.entity} meta={hit.meta} />;
      })}
    </SearchResults>
  );
};
