import { Typography } from '@mui/material';
import objectHash from 'object-hash';
import { create } from 'zustand';

import { ExternalLink } from '@keenious/libraries/components/common/ExternalLink';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import {
  ExcoverQueryDto,
  ExcoverQueryTextTagEnum,
  ExcoverTopicsSearchDto,
  PaginationEntity,
} from '@keenious/libraries/lib/excover';
import { ExternalNavigation } from '@keenious/libraries/lib/navigation';
import { SearchEntity } from '@keenious/libraries/lib/types';
import { useAlertsStore } from '@keenious/libraries/store/useAlertsStore';
import { useEntitiesStore } from '@keenious/libraries/store/useEntitiesStore';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useTopicsStore } from '@keenious/libraries/store/useTopicsStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { LocalStorageUtils } from '@keenious/libraries/utils/LocalStorageUtils';

import { API } from '../lib/API';
import { Environment, environment } from '../lib/environment';
import { useEditorStore } from './useEditorStore';

const MAX_DOCUMENT_LENGTH = 9999999;

type SearchStore = {
  searchId: string | null;
  previousHighlightedText: string;
  searchRequest: ExcoverQueryDto | null;
  searchResponse: SearchEntity | null;
  setSearchId: (id: string) => void;
  setPreviousHighlightedText: (text: string) => void;
  setSearchRequest: (request: ExcoverQueryDto) => void;
  setSearchResponse: (response: SearchEntity) => void;
  resetAndApplySearch: () => Promise<void>;
  applySearch: () => Promise<void>;
  getWorksByTopic: (id: string, applyFilters: boolean, paginationOverride: PaginationEntity) => Promise<SearchEntity>;
  searchTopics: () => Promise<void>;
};

export const useSearchStore = create<SearchStore>((set, get) => ({
  searchId: null,
  previousHighlightedText: '',
  searchRequest: null,
  searchResponse: null,

  setSearchId: (searchId: string) => set({ searchId }),
  setPreviousHighlightedText: (text: string) => set({ previousHighlightedText: text }),
  setSearchRequest: (searchRequest: ExcoverQueryDto) => set({ searchRequest }),
  setSearchResponse: (searchResponse: SearchEntity) => set({ searchResponse }),

  resetAndApplySearch: async () => {
    const { setPagination, pagination } = useFiltersStore.getState();
    setPagination({ ...pagination, offset: 0 });
    await get().applySearch();
    useEntitiesStore.getState().closeAll();
    useUiStore.getState().scrollToTop();
  },

  applySearch: async () => {
    useAlertsStore.getState().closeAllAlerts();
    const state = get();
    const filtersStore = useFiltersStore.getState();
    const uiStore = useUiStore.getState();
    const editorStore = useEditorStore.getState();

    try {
      uiStore.setLoading(true);

      const editor = await editorStore.fetchText();
      const text = editor.text.substring(0, MAX_DOCUMENT_LENGTH).trim();

      if (environment === Environment.GoogleDocs) {
        if (editor.highlightedText !== editorStore.ignoredHighlightedText) {
          filtersStore.setHighlightedText(editor.highlightedText);
          editorStore.setIgnoredHighlightedText('');
        }
      }

      const highlightedText = filtersStore.highlightedText.trim();

      const query: ExcoverQueryDto = {
        query_texts: [
          {
            tag: ExcoverQueryTextTagEnum.DOCUMENT,
            text,
            weight: 1,
          },
          ...(highlightedText.length > 3
            ? [
                {
                  tag: ExcoverQueryTextTagEnum.HIGHLIGHT,
                  text: highlightedText,
                  weight: 1.5,
                },
              ]
            : []),
        ],
        filters: { ...filtersStore.filters },
        query_string: filtersStore.queryString,
        offset: filtersStore.pagination.offset,
        limit: filtersStore.pagination.limit,
      };

      // Don't use spaces to count words, since it's not accurate for languages like Chinese, Japanese, etc.
      // Instead, use the number of characters to count words as an approximation.
      // The average word length in English is 5 characters, so we use that as a rough estimate.
      const wordCount = Math.max(1, Math.floor(text.length / 5));
      // Do not search if text is empty
      if (!wordCount && !filtersStore.filters.works_include_list.length) {
        uiStore.setLoading(false);
        return;
      }

      // Show privacy notice if not accepted
      const privacyNoticeAccepted = LocalStorageUtils.get('privacyNoticeAccepted');
      if (!privacyNoticeAccepted) {
        uiStore.togglePrivacyNoticeDialog(true);
        uiStore.setLoading(false);
        return;
      }

      try {
        const { data } = await API.Search.search(query);

        state.setSearchRequest(query);
        state.setPreviousHighlightedText(highlightedText);
        const requestHash = objectHash(query);
        state.setSearchId(requestHash);
        state.setSearchResponse(data);

        // Don't store search queries for the addon as there's no way to view them
        LocalStorageUtils.clearKeysStartingWith('search-');

        LocalStorageUtils.set(`search-${requestHash}`, query);

        uiStore.setLoading(false);

        AnalyticsUtils.captureEvent('Search: Searched Articles', {
          document_length: query.query_texts[0].text.length,
          highlighted_length: query.query_texts[1]?.text.length || 0,
          keywords_length: query.query_string.length,
          articles_length: data.hits.length || 0,
          document_language: data.meta.query_texts[0].detected_language_name,
          ...query.filters,
        });
      } catch (error) {
        uiStore.setLoading(false);
        console.error(error);
        useAlertsStore.getState().createAlert({ children: 'Failed to search. Please try again later.' });
      }
    } catch (error) {
      uiStore.setLoading(false);
      useAlertsStore.getState().createAlert({
        children: (
          <>
            <Typography>{error.message}</Typography>
            <ExternalLink href={ExternalNavigation.GoogleDocsAuthorizationError} title="Learn more about this error">
              Help
            </ExternalLink>
          </>
        ),
      });
    }
  },

  getWorksByTopic: async (id, applyFilters, paginationOverride) => {
    const { text } = await useEditorStore.getState().fetchText();
    const { filters, highlightedText, pagination } = useFiltersStore.getState();
    const { getTopicById } = useTopicsStore.getState();

    const topic = getTopicById(id);

    const query: ExcoverQueryDto = {
      query_texts: [
        {
          tag: ExcoverQueryTextTagEnum.DOCUMENT,
          text,
          weight: 1,
        },
        ...(highlightedText?.length > 3
          ? [
              {
                tag: ExcoverQueryTextTagEnum.HIGHLIGHT,
                text: highlightedText,
                weight: 1.5,
              },
            ]
          : []),
      ],
      filters: {
        ...(applyFilters && { ...filters }),
        ...(topic.type === 'topic' && {
          topics_include_list: applyFilters ? [id, ...filters.topics_include_list] : [id],
        }),
        ...(topic.type === 'field' && {
          fields_include_list: applyFilters ? [id, ...filters.fields_include_list] : [id],
        }),
        ...(topic.type === 'subfield' && {
          subfields_include_list: applyFilters ? [id, ...filters.subfields_include_list] : [id],
        }),
      },
      offset: paginationOverride.offset || pagination.offset,
      limit: paginationOverride.limit || pagination.limit,
    };

    try {
      const { data } = await API.Search.search(query);
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  searchTopics: async () => {
    const topicsStore = useTopicsStore.getState();
    const editorStore = useEditorStore.getState();
    const uiStore = useUiStore.getState();

    uiStore.setLoading(true);

    const editor = await editorStore.fetchText();
    const text = editor.text.substring(0, MAX_DOCUMENT_LENGTH).trim();

    const query: ExcoverTopicsSearchDto = {
      query_string: topicsStore.queryString || text,
      offset: 0,
      limit: 50,
      synthesize: !topicsStore.queryString,
    };

    try {
      const { data } = await API.Search.searchTopics(query);
      topicsStore.setTopicsRequest(query);

      const topicEntities = data.hits.map((hit) => hit.entity);
      const topics = topicEntities.filter((topic) => topic.type === 'topic');
      const fields = topicEntities.filter((topic) => topic.type === 'field');
      const subfields = topicEntities.filter((topic) => topic.type === 'subfield');

      await topicsStore.cacheAndMergeTopics({ topics, fields, subfields });

      AnalyticsUtils.captureEvent('Search: Searched Topics', {
        document_length: editorStore.text.length,
        keywords_length: query.query_string.length,
        fields_length: fields.length,
        subfields_length: subfields.length,
        topics_length: topics.length,
      });

      topicsStore.setTopicsResponse(data);
    } catch (error) {
      console.error(error);
    }

    uiStore.setLoading(false);
  },
}));
