import { create } from 'zustand';

import { IEditorText } from '@keenious/libraries/types/editor';

import { Environment, environment } from '../lib/environment';
import { OfficeUtils } from '../utils/OfficeUtils';

type EditorStore = {
  text: string;
  highlightedText: string;
  ignoredHighlightedText: string;

  setText(text: string): void;
  setHighlightedText(highlightedText: string): void;
  setIgnoredHighlightedText(ignoredHighlightedText: string): void;
  getHighlightedText(): string;
  fetchText(): Promise<IEditorText>;
};

export const useEditorStore = create<EditorStore>((set, get) => ({
  text: 'The earth is facing universal climate challenges because of the increasing average temperature on earth, leading to global warming. This is a well-known problem and it is a strong consensus saying that global warming is caused by human-made emission of greenhouse gases (GHG), which has increased rapidly since 1750. In 1994, The United Nations Framework Convention on Climate Change, also known as UNFCCC, was established with the objective to stabilize greenhouse gases levels in the atmosphere. There are 197 Parties obligating to the Convention. UNFCCC facilitates regular meetings to discuss climate change actions, trying to prevent climate changes to be harmful towards the humanity and ecosystems. The Intergovernmental Panel on Climate Change (IPCC) provides the Convention with scientific studies and guidelines for GHG reduction.',
  highlightedText: '',
  ignoredHighlightedText: '',

  setText: (text = '') => {
    set({ text: text.replace(/\r/g, '\n') });
  },

  setHighlightedText: (highlightedText = '') => {
    set({ highlightedText: highlightedText.replace(/\r/g, '\n') });
  },

  setIgnoredHighlightedText: (ignoredHighlightedText = '') => {
    set({ ignoredHighlightedText: ignoredHighlightedText.replace(/\r/g, '\n') });
  },

  getHighlightedText: () => {
    if (!get().highlightedText || get().highlightedText === get().ignoredHighlightedText) return '';
    return get().highlightedText;
  },

  fetchText: async () => {
    switch (environment) {
      case Environment.Web:
        return { text: get().text, highlightedText: get().highlightedText };

      case Environment.Word:
        return await OfficeUtils.getText();

      case Environment.GoogleDocs:
        return await new Promise<IEditorText>((resolve, reject) => {
          // Listen for the response
          window.addEventListener('message', function (event) {
            if (event.data.type === 'documentAndSelectedTextResult') {
              resolve({ text: event.data.text, highlightedText: event.data.highlightedText });
            } else if (event.data.type === 'documentAndSelectedTextError') {
              reject(event.data.error);
            }
          });

          // Send the request
          window.parent.postMessage({ type: 'getDocumentAndSelectedText' }, '*');
        });
    }
  },
}));
